import { fasten } from '../../core/js/frontend-api.js';

const makeUserFeedbackUUID = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

export const initUserFeedback = (): void => {
  const userFeedbackElement = <HTMLElement>(
    document.querySelector('#regional-user-feedback')
  );

  if (!userFeedbackElement) {
    return;
  }

  const questionId = userFeedbackElement.getAttribute('data-qid');
  const publication = userFeedbackElement.getAttribute('data-publication');

  const cookiename = `uf-${publication}-${questionId}`;

  if (document.cookie.indexOf(`${cookiename}=1`) !== -1) {
    return;
  }

  let feedbackId = '';
  const baseUrl = 'https://user-feedback.regional.schibsted.tech';

  userFeedbackElement.style.display = 'flex';

  const userFeedbackDone = (): void => {
    userFeedbackElement
      .querySelectorAll('.text-input-container')
      .forEach((element) => element.classList.add('hidden'));

    userFeedbackElement
      .querySelectorAll('.feedback-done-thanks')
      .forEach((element) => element.classList.remove('hidden'));

    const emojiContainers = <NodeListOf<HTMLElement>>(
      userFeedbackElement.querySelectorAll('.emoji-container')
    );

    emojiContainers.forEach((box) => {
      box.style.display = 'none';
    });

    (<HTMLInputElement>(
      document.querySelector('#userfeedback-text-input')
    )).value = '';
  };

  const submitCommentHandler = function () {
    const comment = (<HTMLInputElement>(
      document.querySelector('#userfeedback-text-input')
    )).value;

    const formData = new FormData();
    formData.append('publication', publication);
    formData.append('feedbackId', feedbackId);
    formData.append('comment', comment);

    userFeedbackDone();

    fasten
      .fetch('post', baseUrl + '/comment', {}, 'json', false, formData)
      .then((data) => data);
  };

  const userInputContainerElements = userFeedbackElement.querySelectorAll(
    '.user-input-container',
  );

  userInputContainerElements.forEach(function (element) {
    element.addEventListener('click', function (this: Element) {
      (<HTMLInputElement>(
        document.querySelector('#userfeedback-text-input')
      )).value = '';

      const userFeedbackTextInputElement = document.querySelector(
        '#userfeedback-text-input',
      );

      userFeedbackTextInputElement.addEventListener(
        'keypress',
        function (e: KeyboardEvent) {
          const key = e.keyCode || e.which;

          if (key == 13 || key == 3) {
            submitCommentHandler();
          }
        },
      );

      if (this.classList.contains('disabled')) {
        return null;
      }

      feedbackId = makeUserFeedbackUUID();

      const voteRating =
        this.querySelector<HTMLInputElement>('.user-input-value').value;

      let scale = 0;

      userFeedbackElement.querySelectorAll('.user-input').forEach((element) => {
        element.classList.add('unselected');
        scale++;
      });

      this.querySelectorAll('.user-input').forEach((emoji) => {
        emoji.classList.add('selected');
        emoji.classList.remove('unselected');
      });

      userFeedbackElement
        .querySelector('.text-input-container')
        .classList.remove('hidden');
      userFeedbackElement
        .querySelector('.feedback-form')
        .classList.add('fullWidth');
      userFeedbackElement
        .querySelector('.emoji-container')
        .classList.add('text-phase');
      userFeedbackElement
        .querySelector('.emoji-container')
        .classList.remove('colored');

      const formData = new FormData();
      formData.append('publication', publication);
      formData.append('feedbackId', feedbackId);
      formData.append('rate', voteRating);
      formData.append('scale', scale.toString());
      formData.append('type', 'emoji');
      formData.append('questionId', questionId);

      fasten
        .fetch('post', baseUrl + '/', {}, 'json', false, formData)
        .then(() => {
          document.cookie = `${cookiename}=1; path=/; max-age=604800;`;
          userFeedbackElement
            .querySelectorAll('.user-input-container')
            .forEach((element) => element.classList.add('disabled'));
        })
        .catch(() => userFeedbackDone());

      userFeedbackElement
        .querySelector('.user-input-container')
        .classList.add('disabled');
    });
  });

  document
    .querySelector('#userfeedback-submit')
    .addEventListener('click', submitCommentHandler);
};
